<template>
  <v-card flat>
    <!-- <p
              class="subheading"
              id="v-step-8"
            >Please select which medications you would like to include in the interaction Check</p> -->
    <medications-selection
      :selected="personalInfo.selectedMedication"
      :medications="medicationsList"
      @medicationupdatelist="updateMedicationList"
    ></medications-selection>
  </v-card>
</template>

<script>
import { rules } from '@/util/index'
import MedicationsSelection from './MedicationsSelection.vue'

export default {
  components: {
    'medications-selection': MedicationsSelection,
  },
  props: ['patientProfile', 'medications'],
  data() {
    return {
      personalInfo: this.patientProfile,
      medicationsList: this.medications,

      // selectedMedication: [],
      search: '',
      rules: {
        ...rules,
      },
    }
  },
  created() {
    if (!this.personalInfo.selectedMedication) {
      this.personalInfo.selectedMedication = []
    } else if (this.personalInfo.selectedMedication) {
      return this.personalInfo.selectedMedication
    }
  },
  methods: {
    updateMedicationList(newMedicationList) {
      this.personalInfo.selectedMedication = newMedicationList
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
th {
  font-weight: bold !important;
  text-transform: uppercase;
  background-color: lightgrey;
}
</style>
