import axios from 'axios'

const apiRxInteractionList = 'https://rxnav.nlm.nih.gov/REST/interaction/list.json'
const apiRxNameSearch = 'https://rxnav.nlm.nih.gov/REST/rxcui'

export const getDrugInteractionsByMeds = async medsName => {
  try {
    // i could have a slash which we need to splt them by /
    const medsNameBreakDown = medsName.reduce((cur, v) => {
      const medsCombination = v.split('/')
      if (medsCombination) {
        return [
          ...cur,
          ...medsCombination,
        ]
      }

      return [...cur, v]
    }, [])

    const CUINumsArray = await Promise.all(searchRxCUINum(medsNameBreakDown))
    const interactionsTypes = await getDrugInteractions(CUINumsArray)

    if (!interactionsTypes.fullInteractionTypeGroup) {
      return []
    }

    return new Set(interactionsTypes.fullInteractionTypeGroup[0].fullInteractionType.reduce((cur, interactionType) => [
      ...cur,
      ...interactionType.interactionPair.map(interactionPair => {
        const interactionDescription = interactionPair.description
        const interactionDrugNames = interactionPair.interactionConcept.map(drugName => drugName.sourceConceptItem.name)

        return {
          interactionDescription,
          interactionDrugNames,
        }
      }),
    ], []))
  } catch (e) {
    handleError(e)
  }
}

/// private functions

const rxNameSearch = medNames => axios.get(
  apiRxNameSearch, {
    params: {
      name: medNames,
    },
  },
)

const rxInteractions = CUINumsArray => axios.get(`${apiRxInteractionList}?rxcuis=${CUINumsArray}`)

const searchRxCUINum = medsId => {
  try {
    return medsId.map(async medName => {
      const response = await rxNameSearch(medName)
      if (response.data.idGroup.rxnormId) {
        return response.data.idGroup.rxnormId[0]
      }

      // this is an invalid drug
      return ''
    })
  } catch (e) {
    handleError(e)
  }
}

const getDrugInteractions = async CUINumArray => {
  try {
    const response = await rxInteractions(CUINumArray.filter(v => v != '').join('+'))

    return response.data
  } catch (e) {
    handleError(e)
  }
}

const handleError = e => {
  throw new Error(e)
}
