<template>
  <v-data-table
    v-model="selectedMedication"
    :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
    disable-items-per-page
    :headers="headers"
    :items="medicationsList"
    :items-per-page="20"
    item-key="RxNumber"
    show-select
    event
  >
    <template
      v-for="header in headers.filter((header) =>
        header.hasOwnProperty('formatter')
      )"
      v-slot:[`item.${header.value}`]="{ header, value }"
    >
      {{ header.formatter(value) }}
    </template>
  </v-data-table>
</template>

<script>
import { momentTimeZone } from '@/util/functions/index'

export default {
  props: ['medications', 'selected'],
  data() {
    return {
      medicationsList: this.medications,
      selectedMedication: this.selected || [],
      search: '',
      headers: [
        // { text: 'Rx Number', value: 'RxNumber', width: '10%' },
        {
          text: 'Rx Date',
          value: 'RxDate',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Medication Name',
          value: 'GenericName',
          width: '25%',
        },
        {
          text: 'Strength',
          value: 'MED STR' || 'RxStrength',
          width: '10%',
        },
        {
          text: 'SIG',
          value: 'SIG' || 'SIGFull',
          width: '40%',
        },
        {
          text: 'On Hold',
          value: 'onHold',
          width: '20%',
        },
      ],
    }
  },
  watch: {
    selectedMedication() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  methods: {
    select(item) {
      item.selected = !item.selected
      if (item.selected === true) {
        this.selectedMedication.push(item)
      } else if (this.selectedMedication.length && item.selected === false) {
        const index = this.selectedMedication.findIndex(medication => medication.RxNumber === item.RxNumber)
        this.selectedMedication.splice(index, 1)
      }
      this.$emit('medicationupdatelist', this.selectedMedication)
      this.$forceUpdate()
    },
  },
}
</script>
