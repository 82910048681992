<template>
  <div>
    <v-card
      id="patientformpanel"
      color="info"
      dark
    >
      <v-card-title class="justify-left">
        Drug Interactions Tool
      </v-card-title>
      <!-- <v-row :key="componentTopCardKey"> -->
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="4"
          offset="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <!-- <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Patient Search</span>
              <span class="text--secondary text-xs">Enter Patient info</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider> -->

        <!-- Header: Step 2 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medication List</span>
              <span class="text--secondary text-xs">Select Meds for analysis</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 3 -->
        <v-stepper-step step="2">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Results</span>
              <span class="text--secondary text-xs">List of Drug Interactions</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <!-- <v-stepper-content step="1">
          <v-card
            id="patientSearchId"
            :key="componentKeyPatientSearch"
          >
            <v-card-title>Patient Search</v-card-title>
            <v-card-text>
              <patient-name-search
                @selected-patient="selectedPatient()"
                @search-patient="searchPatient"
              ></patient-name-search>
            </v-card-text>
            <template v-if="loadingPatient">
              <v-overlay
                :opacity=".75"
                :value="loadingPatient"
                style="z-index:30"
              >
                <h2>Â LoadingÂ Data...Â </h2>
                <v-progress-circular
                  class="centerButtons"
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </template>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn
              color="error"
              @click="resetpage"
            >
              Clear
            </v-btn>
            <v-btn
              color="primary"
              @click="activeStep = 2"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content> -->

        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <!-- <v-card class="mb-8" color="secondary" height="200px"></v-card> -->
          <v-card
            id="medListId"
            :key="componentKeyMedList"
          >
            <template slot="title">
              Medication List
            </template>
            <!-- <medications-selection :medications="medicationsList" v-on:medicationupdatelist="updateMedicationList"></medications-selection> -->
            <med-info
              :key="componentKeyRefreshMedList"
              :patient-profile="patientProfile"
              :medications="medicationsList"
              @medication-update-list="updateMedicationList"
            ></med-info>
            <!-- <v-card flat>
                        <v-layout align-start justify-center>
                            <v-btn large color="primary" @click.native="getRxNames">Check Interactions</v-btn>
                        </v-layout>
                    </v-card> -->
          </v-card>

          <div class="d-flex justify-end">
            <!-- <v-btn
              outlined
              @click="activeStep = 1"
            >
              Previous
            </v-btn> -->
            <v-btn
              color="primary"
              @click="getRxNames"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="2">
          <!-- <v-card class="mb-8" color="secondary" height="200px"></v-card> -->
          <v-card
            id="checkinteractionsId"
            :key="componentKeyinteractions"
          >
            <!-- <pg-container :key="refresh"> -->
            <!-- <div slot="content"> -->
            <ul
              v-for="drugInteractionLists in patientProfile.drugInteractionList"
              :key="drugInteractionLists.id"
            >
              <li>
                <h3>{{ drugInteractionLists.interactionDrugs["med1"] }} and {{ drugInteractionLists.interactionDrugs["med2"] }}</h3>
                <p>{{ drugInteractionLists.interactionDescription }}</p>
              </li>
            </ul>
            <!-- <v-layout align-start justify-center>
                                <v-flex xs10 md6 lg5>
                                    <pg-submit formType="drugInteractions" baseFilename="drug-interactions" :patientData="patientProfile" @preaction="ProcessMeds">
                                        Print Report
                                    </pg-submit>
                                </v-flex>
                            </v-layout> -->
            <!-- </div> -->
            <!-- </pg-container> -->
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn
              outlined
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
            <pg-submit
              form-type="drugInteractions"
              base-filename="drug-interactions"
              :patient-data="patientProfile"
              @preaction="ProcessMeds"
            >
              Print Report
            </pg-submit>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import PatientPanel from '@/views/patient/components/PatientPanel.vue'
import Section from '@/components/common/ui/Layout/Section/Section.vue'
import Container from '@/components/common/ui/Layout/Container/Container.vue'
import medInfo from '@/views/pages/tools/MedicationInfo.vue'

// import MedicationsSelection from '../../../components/common/ui/Form/Table/MedicationsSelection/MedicationsSelection'
import MedicationsSelection from '@/views/pages/tools/MedicationsSelection.vue'

// import MedicationInterface from '../../../components/common/MedicationForm/interface'
import { getDrugInteractionsByMeds } from '@/render/api/nih/drugInteractions'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormData'
import PatientNameSearch from '@/components/PatientNameSearch/PatientNameSearch.vue'

export default {
  name: 'DrugInteractions',
  components: {
    'med-info': medInfo,
    'medications-selection': MedicationsSelection,
    'pg-section': Section,
    'pg-container': Container,
    'pg-submit': submitPdf,
    'patient-name-search': PatientNameSearch,
    'app-card-actions': AppCardActions,
    'patient-panel': PatientPanel,
  },
  props: [],
  data() {
    this.$store.dispatch('setFormClass', 'drugInteractions')

    // this.$store.dispatch('getUser')
    return {
      activeStep: 1,
      componentTopCardKey: 0,
      componentKeyRefreshMedList: 0,
      componentKeyPatientSearch: 0,
      componentKeyinteractions: 0,
      componentKeyMedList: 0,
      refresh: true,
      loadingPatient: false,
      patientData: {
        ...JSON.parse(JSON.stringify(this.$store.state.Patient.data)),
        PatientSignature: {},
        PharmacistSignature: {},
        drugInteractionList: {},
      },
      medicationsList: this.$store.state.Patient.medications,
      submitDisabled: true,
      medicationsList: JSON.parse(JSON.stringify(this.$store.state.Patient.medications)),
      patientProfile: {
        ...JSON.parse(JSON.stringify(this.$store.state.Patient.data)),
        PatientSignature: {},
        PharmacistSignature: {},
        drugInteractionList: {},
      },
    }
  },
  computed: {
    ...mapGetters(['PatientProfile', 'UserProfile']),
  },
  mounted() {
    if (this.$store.state.Patient.selectedMedications.length) {
      this.selectedMedication = this.$store.state.Patient.selectedMedications
      this.componentKeyRefreshMedList++
    }

    this.$root.$on('Refresh_Table', patientID => {
      this.selectedPatient()

      // console.log('Message received from Logger Modal')
    })
  },
  methods: {
    forceRerender() {
      this.componentKeyRefreshMedList += 1
      this.componentKeyPatientSearch += 1
    },
    resetpage() {
      location.reload()
    },
    searchPatient() {
      this.loadingPatient = true
      this.$forceUpdate()
    },
    selectedPatient() {
      this.forceRerender()
      this.activeStep = 1
      this.patientProfile = {
        ...this.PatientProfile,
      }
      this.patientData = this.patientProfile
      this.loadingPatient = false
      this.medicationsList = this.$store.getters.PatientMedications
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
    },

    ProcessMeds(patientData) {
      this.componentKeyPatientSearch += 1
      patientData = serverFormProcess(this.patientProfile.selectedMedication, patientData)
    },
    updateMedicationList(newMedicationList) {
      this.patientProfile.selectedMedication = JSON.parse(JSON.stringify(newMedicationList))
      this.populateDrugInteractions(this.patientProfile.drugInteractionList)
      this.submitDisabled = !this.patientProfile.selectedMedication.length
      this.$forceUpdate()
    },
    selected(item) {
      item.selected = !item.selected
      this.$emit('medication-update-list', this.patientProfile.selectedMedication)
      this.$forceUpdate()
    },
    clearselected() {
      this.forceRerender()
      this.activeStep = 2
      ;(this.patientProfile = {
        ...this.PatientProfile,
      }),
        (this.patientData = this.patientProfile),
        (this.loadingPatient = false),
        (this.medicationsList = this.$store.getters.PatientMedications)
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
    },
    restart() {
      this.activeStep = 1
      this.componentKeyRefreshMedList += 1
      this.componentKeyPatientSearch += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
    },
    async getRxNames() {
      // console.log('check')
      const listOfMedsToCheck = this.patientProfile.selectedMedication
        .filter(med => !med['DISCONTINUED MEDS'] && med.GenericName)
        .map(med => med.GenericName)

      // console.log(listOfMedsToCheck)
      this.componentKeyinteractions += 1

      // this.componentKeyMedList += 1
      await getDrugInteractionsByMeds(listOfMedsToCheck).then(drugInteractions => {
        if (drugInteractions) {
          const drugArray = Array.from(drugInteractions)
          this.patientProfile.drugInteractionList = drugArray.map(index => ({
            interactionDescription: index.interactionDescription,
            interactionDrugs: {
              med1: index.interactionDrugNames[0],
              med2: index.interactionDrugNames[1],
            },
          }))
          this.populateDrugInteractions(this.patientProfile.drugInteractionList)
          this.patientProfile = {
            ...this.patientProfile,
            ...this.patientProfile.drugInteractionList,
            'DATE OF BIRTH': this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
          }
        } else {
          this.patientProfile.drugInteractionList = 'No drug interactions indicated.'
        }
      })
      this.activeStep = 2
    },
    populateDrugInteractions(drugInteractionList) {
      drugInteractionList = this.patientProfile.drugInteractionList.forEach((interaction, index) => {
        const cur = index + 1
        drugInteractionList[
          `interaction${cur}`
        ] = `${interaction.interactionDrugs.med1} and ${interaction.interactionDrugs.med2}: ${interaction.interactionDescription}`
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#headersTable {
  .theme--dark.v-data-table {
    background-color: #95a6df80 !important;
    color: #ffffff !important;
  }
  .theme--dark.v-data-table td {
    color: rgb(255 255 255) !important;
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
#buttons {
  margin: 1%;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
